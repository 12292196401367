import { useAuth } from "@/hooks/useAuth.tsx";
import { imageUrl } from "@/utils/image-url.ts";
import { Image } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";

export function ProductDetail() {
  const { id } = useParams();
  const { data, error, isLoading } = useSWR("/products/" + id);

  if (error) return <div>failed to load</div>;
  if (isLoading) return <div>loading...</div>;

  return <DetailCard data={data} />;
}

export function DetailCard(props: any) {
  const navigate = useNavigate();
  const auth = useAuth();
  console.log(auth);
  const editAuth = auth.state.user.permissions.some(
    (i: any) => i.permissions.name === "product_edit",
  );

  const { data } = props;
  return (
    <div className={"flex flex-col gap-2 p-2"}>
      <div className={"flex gap-2"}>
        <button
          className={"rounded border border-black px-2"}
          style={{ display: editAuth ? "inline-block" : "none" }}
          onClick={() => navigate(`/product_detail/${data.id}/edit`)}
        >
          修改
        </button>
      </div>
      <h1 className={"bg-gray-200 p-1 font-bold"}>备件情况：</h1>
      <div className={"flex flex-col gap-2"}>
        <div className={"flex justify-between gap-2"}>
          <Field label={"编号"} value={data.PartCode} />
          <Field label={"备件号"} value={data.PartNo} />
        </div>
        <div className={"flex justify-between gap-2"}>
          <Field label={"备件中文名称"} value={data.title} />
          <Field label={"参考号码"} value={data.RefNo} />
        </div>
        <div className={"flex justify-between gap-2"}>
          <Field label={"备件英文名称"} value={data.title_en} />
          <Field label={"库存位置"} value={data.Location} />
        </div>
        <div className={"flex justify-between gap-2"}>
          <Field label={"数量单位"} value={data.unit} />
          <Field label={"类型"} value={data.type} />
        </div>
        <div className={"flex justify-between gap-2"}>
          <Field label={"原厂价"} value={data.factory_price} />
          <Field label={"供应商"} value={data.provider} />
        </div>
        <div className={"flex justify-between gap-2"}>
          <Field label={"进货价"} value={data.purchase_price} />
          <Field label={"销售A价"} value={data.price_a} />
        </div>
        <div className={"flex justify-between gap-2"}>
          <Field label={"销售B价"} value={data.price_b} />
          <Field label={"销售C价"} value={data.price_c} />
        </div>
        <div className={"flex justify-between gap-2"}>
          <Field label={"同行价格"} value={data.tonghang} />
          <Field label={"报关价"} value={data.baoguan} />
        </div>
      </div>
      <div className={"h-10"}></div>
      {/*图片*/}
      <h1 className={"bg-gray-200 p-1 font-bold"}>备件图片：</h1>
      {(!data.product_media || data.product_media.length === 0) && (
        <div>没有图片</div>
      )}
      <div>
        <div className="flex gap-1">
          {data.product_media?.map(
            (item: { media: { id: number; url: string } }, index: any) => (
              <Image
                width={200}
                height={200}
                key={index}
                src={imageUrl(item.media.url)}
                style={{
                  objectFit: "contain",
                  border: "1px solid #ccc",
                  backgroundColor: "#ccc",
                }}
              />
            ),
          )}
        </div>
      </div>
    </div>
  );
}

function Field(props: { label: string; value: string }) {
  return (
    <div
      className={"flex items-center border-b border-black p-1"}
      style={{ width: "46%" }}
    >
      <span className={"w-28"}>{props.label}：</span>
      <span>{props.value}</span>
    </div>
  );
}
