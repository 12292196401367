import App from "./App.tsx";
import "./index.css";
import { client } from "@/api/axios.ts";
import { ConfigProvider } from "antd";
import zhCN from "antd/locale/zh_CN";
import "dayjs/locale/zh-cn";
import React from "react";
import ReactDOM from "react-dom/client";
import { SWRConfig } from "swr";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ConfigProvider locale={zhCN}>
      <SWRConfig
        value={{
          refreshInterval: 30000,
          fetcher: async (resource) => {
            const res = await client.get(resource);
            return res.data;
          },
        }}
      >
        <App />
      </SWRConfig>
    </ConfigProvider>
  </React.StrictMode>,
);
