import { client } from "@/api/axios.ts";
import { message } from "antd";

export function Setting() {
  const [messageApi] = message.useMessage();

  const import_handler = async () => {
    const txt = document.querySelector("textarea")?.value;
    try {
      await client.post("/products/import", { txt });
      messageApi.open({
        type: "success",
        content: "导入成功",
      });
    } catch (error) {
      console.error("Error setting setting", error);
      messageApi.open({
        type: "error",
        content: "导入出错",
      });
    }
  };

  return (
    <div className={"flex flex-col"}>
      <div className={"mb-2 text-2xl font-bold"}>
        <span>导入配件</span>
      </div>
      <textarea
        className={"rounded-md border p-2"}
        name=""
        id=""
        cols={30}
        rows={10}
      ></textarea>
      <button
        className={"mt-2 rounded-md border py-2"}
        onClick={import_handler}
      >
        导入
      </button>
    </div>
  );
}
