import { client } from "@/api/axios";
import { TITLE } from "@/config";
import { useAuth } from "@/hooks/useAuth";
import { LOGIN } from "@/provider/AuthProvider";
import { cacheAxios, myalert } from "@/utils/ErrorHandler";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const LoginForm: React.FC = () => {
  const navigate = useNavigate();

  const { dispatch } = useAuth();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinish = (values: any) => {
    client
      .post("/web/login", values)
      .then((data) => {
        console.log("/web/auth", data);
        if (data.status === 200) {
          // 登录或注册成功后的处理
          localStorage.setItem("accessToken", data.data.accessToken);
          localStorage.setItem("refreshToken", data.data.refreshToken);
          console.log("Logged in successfully");
          dispatch({ type: LOGIN });
          navigate("/product_order");
        } else {
          // 处理错误
          console.error("Error 2:", data.statusText);
          myalert(data.statusText);
        }
      })
      .catch((error) => {
        console.error("Error 1:", error);
        cacheAxios(error);
      });
  };

  return (
    <Form
      name="normal_login"
      className="login-form"
      initialValues={{ remember: true }}
      onFinish={onFinish}
    >
      <Form.Item
        name="email"
        rules={[{ required: true, message: "Please input your Username!" }]}
      >
        <Input
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="邮箱"
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: "Please input your Password!" }]}
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="密码"
        />
      </Form.Item>

      <Form.Item>
        <Button
          block
          type="primary"
          htmlType="submit"
          className="login-form-button"
        >
          登录
        </Button>
      </Form.Item>
    </Form>
  );
};

const WebLogin: React.FC = () => {
  useEffect(() => {}, []);
  return (
    <div className="flex h-screen items-center justify-center">
      <div className="rounded-lg border p-8">
        <h1 className="mb-4 text-center text-lg font-bold">{TITLE}</h1>
        <LoginForm />
      </div>
    </div>
  );
};

export default WebLogin;
