import { PRODUCT_TITLE } from "@/config";
import ManageProduct from "@/router/manage-product";
import product_order from "@/router/product_order";
import { Setting } from "@/router/setting.tsx";
import hello from "@/router/users";

export const routerInfo = [
  {
    path: "/product_order",
    title: "订单",
    Component: product_order,
  },
  {
    path: "/manage-product",
    title: `${PRODUCT_TITLE}`,
    Component: ManageProduct,
  },
  {
    path: "/hello",
    title: "用户",
    Component: hello,
  },
  {
    path: "/setting",
    title: "设置",
    Component: Setting,
  },
];
