import { client } from "@/api/axios.ts";
import InputField from "@/components/InputField";
import MyUpload from "@/components/upload.tsx";
import { message } from "antd";
import { makeAutoObservable } from "mobx";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";

class FormStore {
  formData = {
    PartCode: "",
    PartNo: "",
    RefNo: "",
    title: "",
    title_en: "",
    Location: "",
    unit: "",
    note: "",
    factory_price: "",
    provider: "",
    purchase_price: "",
    price_a: "",
    price_b: "",
    price_c: "",
    UpdatedBy: "",
    Revisions: "",

    version: "",
    tonghang: "",
    baoguan: "",

    product_media: [] as any[],
    media: [] as any[],
  };

  constructor() {
    makeAutoObservable(this);
  }

  setFormData(data: any) {
    this.formData = { ...data };
  }

  updateField(key: string, value: string) {
    // @ts-ignore
    this.formData[key] = value;
  }

  saveForm() {
    console.log("Form data saved:", this.formData);
    // 可以在这里添加实际的保存逻辑，例如发送请求到后台API
  }
}

export function ProductDetailEdit() {
  const { id } = useParams();
  const { data, error, isLoading } = useSWR("/products/" + id);

  if (error) return <div>failed to load</div>;
  if (isLoading) return <div>loading...</div>;

  return <DetailCard data={data} />;
}

const formStore = new FormStore();
export const DetailCard = observer((props: any) => {
  const navigate = useNavigate();
  const params = useParams();
  const data = formStore.formData;

  useEffect(() => {
    if (props.data) {
      formStore.setFormData({
        ...props.data,
        media: props.data.product_media.map((i: any) => i.media),
      });
    }
  }, [props.data]);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    formStore.updateField(name, value);
  };

  const handleSave = async () => {
    try {
      await client.put("/products/" + params.id, { ...formStore.formData });
      message.info("保存成功");
      navigate(`/product_detail/${params.id}`);
    } catch (error) {
      console.error(error);
      message.error("保存出错");
    }
  };

  return (
    <div className={"flex flex-col gap-2 p-2"}>
      <div className={"flex gap-2"}>
        <button
          className={"rounded border px-2"}
          onClick={() => {
            navigate(`/product_detail/${params.id}`);
          }}
        >
          取消修改
        </button>
        <button className={"rounded border px-2"} onClick={handleSave}>
          保存修改
        </button>
      </div>
      <h1 className={"bg-gray-200 p-1 font-bold"}>备件情况：</h1>
      <div className={"flex flex-col gap-2"}>
        <div className={"flex justify-between gap-2"}>
          <InputField
            label={"编号"}
            value={data.PartCode}
            name="PartCode"
            onChange={handleChange}
          />
          <InputField
            label={"备件号"}
            value={data.PartNo}
            name="PartNo"
            onChange={handleChange}
          />
        </div>
        <div className={"flex justify-between gap-2"}>
          <InputField
            label={"备件中文名称"}
            value={data.title}
            name="title"
            onChange={handleChange}
          />
          <InputField
            label={"参考号码"}
            value={data.RefNo}
            name="RefNo"
            onChange={handleChange}
          />
        </div>
        <div className={"flex justify-between gap-2"}>
          <InputField
            label={"备件英文名称"}
            value={data.title_en}
            name="title_en"
            onChange={handleChange}
          />
          <InputField
            label={"库存位置"}
            value={data.Location}
            name="Location"
            onChange={handleChange}
          />
        </div>
        <div className={"flex justify-between gap-2"}>
          <InputField
            label={"数量单位"}
            value={data.unit}
            name="unit"
            onChange={handleChange}
          />
          <InputField
            label={"类型"}
            value={data.version}
            name="version"
            onChange={handleChange}
          />
        </div>
        <div className={"flex justify-between gap-2"}>
          <InputField
            label={"原厂价"}
            value={data.factory_price}
            name="factory_price"
            onChange={handleChange}
          />
          <InputField
            label={"供应商"}
            value={data.provider}
            name="provider"
            onChange={handleChange}
          />
        </div>
        <div className={"flex justify-between gap-2"}>
          <InputField
            label={"进货价"}
            value={data.purchase_price}
            name="purchase_price"
            onChange={handleChange}
          />
          <InputField
            label={"销售A价"}
            value={data.price_a}
            name="price_a"
            onChange={handleChange}
          />
        </div>
        <div className={"flex justify-between gap-2"}>
          <InputField
            label={"销售B价"}
            value={data.price_b}
            name="price_b"
            onChange={handleChange}
          />
          <InputField
            label={"销售C价"}
            value={data.price_c}
            name="price_c"
            onChange={handleChange}
          />
        </div>
        <div className={"flex justify-between gap-2"}>
          <InputField
            label={"同行价格"}
            value={data.tonghang}
            name="tonghang"
            onChange={handleChange}
          />
          <InputField
            label={"报关价"}
            value={data.baoguan}
            name="baoguan"
            onChange={handleChange}
          />
        </div>
      </div>
      <div className={"h-10"}></div>
      {/*图片*/}
      <h1 className={"bg-gray-200 p-1 font-bold"}>备件图片：</h1>
      <div>
        <MyUpload
          medias={data?.media}
          onChange={(media) => {
            console.log(media);
            formStore.updateField("media", media);
          }}
        />
      </div>
    </div>
  );
});
