import { Table } from "antd";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";

export function TableWithData(props: any) {
  const { data, isLoading, error } = useSWR(props.dataSourceUrl);
  const navigate = useNavigate();

  if (error) return <div>failed to load</div>;
  if (isLoading) return <div>loading...</div>;

  if (props.detailUrl) {
    props.columns.push({
      title: "操作",
      render: (_: any, record: any) => (
        <>
          <a
            onClick={() => {
              navigate(`${props.detailUrl}/${record.id}`);
            }}
            className={"text-sky-600"}
          >
            查看详情
          </a>
        </>
      ),
    });
  }

  const dataSource = props.filter
    ? (data?.data || data).filter(props.filter)
    : data?.data || data;

  return (
    <Table
      rowKey="id"
      columns={props.columns}
      dataSource={dataSource}
      pagination={false}
      onRow={(record, rowIndex) => {
        return {
          onClick: () => {
            if (props.onRolwClick) {
              props.onRolwClick(record, rowIndex);
            }
          }, // 点击行
        };
      }}
    />
  );
}
