import "./App.css";
import AuthLayout from "./components/AuthLayout";
import { TITLE } from "./config";
import { AuthProvider } from "./provider/AuthProvider";
import { routerInfo } from "./router/router-info";
import WebLogin from "./router/web-login";
import AppLayout from "@/components/AppLayout.tsx";
import { Detail } from "@/router/detail.tsx";
import ErrorPage from "@/router/error-page.tsx";
import ManageProduct from "@/router/manage-product";
import { ProductDetail } from "@/router/product_detail.tsx";
import { ProductDetailEdit } from "@/router/product_detail_edit.tsx";
import { UsersAdd } from "@/router/users_add.tsx";
import { useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/login",
    Component: WebLogin,
  },
  // 需要权限的路由全部放在children中
  {
    path: "/",
    element: <AuthLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        Component: AppLayout,
        children: [
          {
            index: true,
            path: "/",
            Component: ManageProduct,
          },
          {
            path: "/detail/:id",
            Component: Detail,
          },
          {
            path: "/product_detail/:id/edit",
            Component: ProductDetailEdit,
          },
          {
            path: "/product_detail/:id",
            Component: ProductDetail,
          },
          {
            path: "/users/add",
            Component: UsersAdd,
          },
          {
            path: "/users/:id/edit",
            Component: UsersAdd,
          },
          ...routerInfo,
        ],
      },
    ],
  },
]);

function App() {
  useEffect(() => {
    document.title = TITLE;
  }, []);
  return (
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  );
}

export default App;
