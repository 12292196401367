export default function ProductCard(props: any) {
  const {
    title,
    title_en,
    PartCode,
    PartNo,
    RefNo,
    Location,
    unit,
    note,
    factory_price,
    provider,
    purchase_price,
    price_a,
    price_b,
    price_c,
    UpdatedBy,
    Revisions,
  } = props.data;
  return (
    <div className="mx-auto max-w-xs rounded-lg bg-white p-4 shadow">
      <div className="mb-4">
        <p className="text-sm text-gray-600">编号: {PartCode}</p>
        <p className="text-sm text-gray-600">备件号: {PartNo}</p>
        <p className="text-sm text-gray-600">参考号码: {RefNo}</p>
        <p className="text-sm text-gray-600">备件中文名称: {title}</p>
        <p className="text-sm text-gray-600">备件名称: {title_en}</p>
        <p className="text-sm text-gray-600">库存位置: {Location}</p>
        <p className="text-sm text-gray-600">单位: {unit}</p>
        <p className="text-sm text-gray-600">说明: {note}</p>
        <p className="text-sm text-gray-600">原厂价: {factory_price}</p>
        <p className="text-sm text-gray-600">供应商: {provider}</p>
        <p className="text-sm text-gray-600">进货价: {purchase_price}</p>
        <p className="text-sm text-gray-600">销售A价: {price_a}</p>
        <p className="text-sm text-gray-600">销售B价: {price_b}</p>
        <p className="text-sm text-gray-600">销售C价: {price_c}</p>
      </div>
      <div className="text-xs text-gray-500">
        <p>$UpdatedBy:{UpdatedBy}</p>
        <p>$Revisions: {Revisions}</p>
      </div>
    </div>
  );
}
