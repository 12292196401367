import { Input } from "antd";

export default function InputField(props: {
  label: string;
  value: string;
  name: string;
  onChange: any;
}) {
  return (
    <div
      className={"flex items-center border-b border-black p-1"}
      style={{ width: "46%" }}
    >
      <span className={"w-32"}>{props.label}：</span>
      <Input
        placeholder="请输入数据"
        name={props.name}
        value={props.value}
        onChange={props.onChange}
      />
    </div>
  );
}
