import ProductCard from "@/components/ProductCard.tsx";
import { TableWithData } from "@/components/TableWithData.tsx";
import useMobile from "@/hooks/useMobile.tsx";
import { Input } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";

/*
编号:  01001
备件号:  5430704
备件中文名称:  专用整流器
参考号码:  36604-178
备件名称:  Diode
库存位置:  01
单位:  pc
说明:  SHUTDOWN
原厂价:  722.96
供应商:
进货价:
销售A价:  1156.74
销售B价:  1156.74
销售C价:  1445.92
$UpdatedBy:  CN=陈成坤/O=mzl,CN=马自力/O=mzl,CN=薛立滨/O=steve,CN=mzl/O=steve,CN=徐紫华/O=steve,CN=mzl/O=steve
$Revisions:  2006/05/07 01:05:07,2006/05/11 09:54:46,2006/07/25 14:20:39,2006/11/07 10:24:03,2007/10/22 15:22:17
 */
const ManageProducts: React.FC = () => {
  const { isMobile } = useMobile();

  const columns = [
    {
      title: "备件号",
      dataIndex: "PartNo",
      key: "PartNo",
    },
    {
      title: "单位",
      dataIndex: "unit",
      key: "unit",
    },
    {
      title: "备件名称",
      dataIndex: "title_en",
      key: "title_en",
    },
    {
      title: "说明",
      dataIndex: "note",
      key: "note",
      render: (note: string) => (
        <p className="line-clamp-2 max-w-28" title={note}>
          {note}
        </p>
      ),
    },
  ];

  const { data, isLoading, error } = useSWR("/products");
  const [keyword, setKeyword] = useState("");
  const navigate = useNavigate();

  const filter = (record: any) => {
    return (
      record.PartNo?.includes(keyword) ||
      record.title?.includes(keyword) ||
      record.title_en?.includes(keyword) ||
      record.note?.includes(keyword)
    );
  };

  console.log(data);
  if (error) return <div>failed to load</div>;
  if (isLoading) return <div>loading...</div>;

  if (isMobile) {
    return (
      <div className={"flex flex-col gap-4"}>
        <h1>备件列表</h1>
        <Input.Search />
        {data.data.length > 0 &&
          data.data
            .filter(filter)
            .map((i: any) => <ProductCard key={i.id} data={i} />)}
      </div>
    );
  }

  return (
    <div>
      <div className={"mb-4 flex w-full items-center gap-4"}>
        <h1 className={"text-2xl font-bold"}>备件列表</h1>
        <div className={"ml-4 flex items-center"} style={{ width: "50%" }}>
          <span className={"w-12"}>搜索：</span>
          <Input.Search
            onSearch={setKeyword}
            defaultValue={keyword}
            allowClear
          />
        </div>
      </div>
      <TableWithData
        columns={columns}
        dataSourceUrl="/products"
        filter={filter}
        title="订单"
        onRolwClick={(record: any) => {
          navigate("/product_detail/" + record.id);
        }}
      />
    </div>
  );
};

export default ManageProducts;
