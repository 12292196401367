import { client } from "@/api/axios.ts";
import InputField from "@/components/InputField";
import { Checkbox, message } from "antd";
import { makeAutoObservable } from "mobx";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";

class FormStore {
  formData = {
    email: "",
    password: "",
    username: "",
    nickname: "",
    permission_ids: [] as string[],
  };

  constructor() {
    makeAutoObservable(this);
  }

  setFormData(data: any) {
    this.formData = { ...data };
  }

  updateField(key: string, value: any) {
    // @ts-ignore
    this.formData[key] = value;
  }

  saveForm() {
    console.log("Form data saved:", this.formData);
    // 可以在这里添加实际的保存逻辑，例如发送请求到后台API
  }
}

export function UsersAdd() {
  const { id } = useParams();

  if (id) {
    return <Edit />;
  }

  return <DetailCard />;
}

function Edit() {
  const { id } = useParams();
  const { data, error, isLoading } = useSWR("/users/a/" + id);

  if (error) return <div>failed to load</div>;
  if (isLoading) return <div>loading...</div>;

  return <DetailCard data={data} />;
}

const formStore = new FormStore();

export const DetailCard = observer((props: any) => {
  const navigate = useNavigate();
  const data = formStore.formData;
  const isEdit = !!props.data;

  console.log(321, props.data.user_permissions);

  useEffect(() => {
    if (props.data) {
      formStore.setFormData(props.data);
    }
  }, [props.data]);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    formStore.updateField(name, value);
  };

  const handleSave = async () => {
    try {
      if (isEdit) {
        await client.put(`/users/${props.data.id}`, {
          ...formStore.formData,
        });
      } else {
        await client.post("/register/user", {
          ...formStore.formData,
        });
      }

      message.info("保存成功");
      navigate(`/hello`);
    } catch (error) {
      console.error(error);
      message.error("保存出错");
    }
  };

  return (
    <div className={"flex flex-col gap-2 p-2"}>
      <div className={"flex gap-2"}>
        <button
          className={"rounded border px-2"}
          onClick={() => {
            navigate(`/hello`);
          }}
        >
          取消
        </button>
        <button className={"rounded border px-2"} onClick={handleSave}>
          保存
        </button>
      </div>
      <h1 className={"bg-gray-200 p-1 font-bold"}>备件情况：</h1>
      <div className={"flex flex-col gap-2"}>
        <div className={"flex justify-between gap-2"}>
          <InputField
            label={"登录邮箱"}
            value={data.email}
            name="email"
            onChange={handleChange}
          />
          <InputField
            label={"登录密码"}
            value={data.password}
            name="password"
            onChange={handleChange}
          />
        </div>
        <div className={"flex justify-between gap-2"}>
          <InputField
            label={"用户名称"}
            value={data.username}
            name="username"
            onChange={handleChange}
          />
        </div>
      </div>
      <h1 className={"bg-gray-200 p-1 font-bold"}>权限设置：</h1>
      <Permissions
        permission_ids={props.data.user_permissions.map(
          (i: any) => i.permission_id,
        )}
        onChange={(checked: string[]) => {
          formStore.updateField("permission_ids", checked);
        }}
      />
    </div>
  );
});

function Permissions(props: any) {
  const { data, error, isLoading } = useSWR("/permissions");

  if (error) return <div>failed to load</div>;
  if (isLoading) return <div>loading...</div>;

  const options = data.map((i: any) => ({
    label: i.description,
    value: i.id,
  }));

  console.log(1, props.permission_ids);

  return (
    <div className={"flex flex-col gap-2 p-2"}>
      <Checkbox.Group
        options={options}
        defaultValue={props.permission_ids}
        onChange={props.onChange}
      />
    </div>
  );
}
